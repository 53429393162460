<template>
    <section class="timing-total" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Тайминг"</h2>
        <div class="timing-total__averate-time" v-if="modeId === 'averageTime'">
            <div class="timing-total__box box">
                <table class="timing-total__table timing-total__table_striped timing-total__table_hover">
                    <colgroup>
                        <col class="timing-total__table-column timing-total__table-column_title">
                        <col class="timing-total__table-column timing-total__table-column_cooking-duration">
                        <col class="timing-total__table-column timing-total__table-column_stand-duration">
                        <col class="timing-total__table-column timing-total__table-column_waiting-duration">
                        <col class="timing-total__table-column timing-total__table-column_delivery-duration">
                        <col class="timing-total__table-column timing-total__table-column_total-duration">
                        <col class="timing-total__table-column timing-total__table-column_return-duration">
                    </colgroup>
                    <thead>
                        <tr class="timing-total__table-row timing-total__table-row_head">
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'title')"
                            >
                                <span class="timing-total__table-value">Источник</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'cooking_duration')"
                            >
                                <span class="timing-total__table-value">На кухне</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'stand_duration')"
                            >
                                <span class="timing-total__table-value">В отстойнике</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'waiting_duration')"
                            >
                                <span class="timing-total__table-value">До отправки</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'delivery_duration')"
                            >
                                <span class="timing-total__table-value">В пути</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'total_duration')"
                            >
                                <span class="timing-total__table-value">Общее</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('averageTime', 'return_duration')"
                            >
                                <span class="timing-total__table-value">Обратно</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_duration' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_duration' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="timing-total__table-row timing-total__table-row_body timing-total__table-row_pinned">
                            <td class="timing-total__table-cell timing-total__table-cell_title">Норма</td>
                            <td class="timing-total__table-cell">
                                {{standard.cooking_duration | durationFormat}}
                            </td>
                            <td class="timing-total__table-cell">
                                {{standard.stand_duration | durationFormat}}
                            </td>
                            <td class="timing-total__table-cell">
                                {{standard.waiting_duration | durationFormat}}
                            </td>
                            <td class="timing-total__table-cell">
                                {{standard.delivery_duration | durationFormat}}
                            </td>
                            <td class="timing-total__table-cell">
                                {{standard.total_duration | durationFormat}}
                            </td>
                            <td class="timing-total__table-cell">
                                {{standard.return_duration | durationFormat}}
                            </td>
                        </tr>
                        <tr class="timing-total__table-row timing-total__table-row_body timing-total__table-row_pinned"
                            v-if="restaurants.length > 1"
                        >
                            <td class="timing-total__table-cell timing-total__table-cell_title">Итого</td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{total.cooking_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="total.cooking_duration"
                                    :compareValue="standard.cooking_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{total.stand_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="total.stand_duration"
                                    :compareValue="standard.stand_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{total.waiting_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="total.waiting_duration"
                                    :compareValue="standard.waiting_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{total.delivery_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="total.delivery_duration"
                                    :compareValue="standard.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{total.total_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="total.total_duration"
                                    :compareValue="standard.total_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{total.return_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="total.return_duration"
                                    :compareValue="standard.return_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="timing-total__table-row timing-total__table-row_body"
                            v-for="restaurant in restaurants"
                            :key="restaurant.id"
                        >
                            <td class="timing-total__table-cell timing-total__table-cell_title">
                                <router-link class="daterange-report__link link link_red"
                                    :to="{ name: 'TimingRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть тайминг ресторана ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{restaurant.total.cooking_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="restaurant.total.cooking_duration"
                                    :compareValue="standard.cooking_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{restaurant.total.stand_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="restaurant.total.stand_duration"
                                    :compareValue="standard.stand_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{restaurant.total.waiting_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="restaurant.total.waiting_duration"
                                    :compareValue="standard.waiting_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{restaurant.total.delivery_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="restaurant.total.delivery_duration"
                                    :compareValue="standard.delivery_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{restaurant.total.total_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="restaurant.total.total_duration"
                                    :compareValue="standard.total_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="timing-total__table-cell">
                                <span class="timing-total__table-value">{{restaurant.total.return_duration | durationFormat}}</span>
                                <difference class="timing-total__table-value"
                                    :primaryValue="restaurant.total.return_duration"
                                    :compareValue="standard.return_duration"
                                    mode="percentage"
                                    good="negative"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="timing-total__late-data" v-else-if="modeId === 'lateData'">
            <div class="timing-total__box box">
                <table class="timing-total__table timing-total__table_striped timing-total__table_hover">
                    <colgroup>
                        <col class="timing-total__table-column timing-total__table-column_late-title">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                        <col class="timing-total__table-column timing-total__table-column_late-value">
                    </colgroup>
                    <thead>
                        <tr class="timing-total__table-row timing-total__table-row_head">
                            <th class="timing-total__table-cell timing-total__table-cell_baseline timing-total__table-cell_head timing-total__table-cell_sortable"
                                scope="col"
                                rowspan="2"
                                @click="setSorting('lateData', 'title')"
                            >
                                <span class="timing-total__table-value">Источник</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'title' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_right" scope="col" colspan="2">На кухне</th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_right" scope="col" colspan="2">В отстойнике</th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_right" scope="col" colspan="2">До отправки</th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_right" scope="col" colspan="2">В пути</th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_right" scope="col" colspan="2">Всего</th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_right" scope="col" colspan="2">Обратно</th>
                        </tr>
                        <tr class="timing-total__table-row timing-total__table-row_head">
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'cooking_late')"
                            >
                                <span class="timing-total__table-value">∑</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'cooking_percent_late')"
                            >
                                <span class="timing-total__table-value">%</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'cooking_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'stand_late')"
                            >
                                <span class="timing-total__table-value">∑</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'stand_percent_late')"
                            >
                                <span class="timing-total__table-value">%</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'stand_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'waiting_late')"
                            >
                                <span class="timing-total__table-value">∑</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'waiting_percent_late')"
                            >
                                <span class="timing-total__table-value">%</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'waiting_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'delivery_late')"
                            >
                                <span class="timing-total__table-value">∑</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'delivery_percent_late')"
                            >
                                <span class="timing-total__table-value">%</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'delivery_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'total_late')"
                            >
                                <span class="timing-total__table-value">∑</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'total_percent_late')"
                            >
                                <span class="timing-total__table-value">%</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'total_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'return_late')"
                            >
                                <span class="timing-total__table-value">∑</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_right timing-total__table-cell_small timing-total__table-cell_low"
                                scope="col"
                                @click="setSorting('lateData', 'return_percent_late')"
                            >
                                <span class="timing-total__table-value">%</span>
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_percent_late' && sortings[modeId].direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                    v-if="sortings[modeId].field === 'return_percent_late' && sortings[modeId].direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="timing-total__table-row timing-total__table-row_body timing-total__table-row_pinned"
                            v-if="restaurants.length > 1"
                        >
                            <td class="timing-total__table-cell timing-total__table-cell_title">Итого</td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.cooking_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.cooking_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.stand_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.stand_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.waiting_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.waiting_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.delivery_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.delivery_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.total_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.total_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.return_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{total.return_percent_late | percentFloatFormat}}
                            </td>
                        </tr>
                        <tr class="timing-total__table-row timing-total__table-row_body"
                            v-for="restaurant in restaurants"
                            :key="restaurant.id"
                        >
                            <td class="timing-total__table-cell timing-total__table-cell_title">
                                <router-link class="daterange-report__link link link_red"
                                    :to="{ name: 'TimingRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть тайминг ресторана ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.cooking_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.cooking_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.stand_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.stand_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.waiting_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.waiting_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.delivery_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.delivery_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.total_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.total_percent_late | percentFloatFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.return_late | intFormat}}
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_small timing-total__table-cell_right">
                                {{restaurant.total.return_percent_late | percentFloatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="timing-total__late-distribution" v-else-if="modeId === 'lateDistribution'">
            <figure class="timing-total__box box">
                <highcharts :options="hoursChartOptions" />
            </figure>
        </div>
        <div class="timing-total__late-distribution" v-else-if="modeId === 'deliveryTimeDistribution'">
            <div class="timing-total__box box">
                <table class="timing-total__table timing-total__table_striped timing-total__table_hover">
                    <col class="timing-total__table-column timing-total__table-column_delivery-distribution-title">
                    <col v-for="deliveryTimeDistrubutionItem in totalDeliveryTimeDistrubution"
                        :key="deliveryTimeDistrubutionItem.id"
                        class="timing-total__table-column timing-total__table-column_delivery-distribution"
                        :class="`timing-total__table-column_delivery-distribution-${deliveryTimeDistrubutionItem.id}`"
                    >
                    <col class="timing-total__table-column timing-total__table-column_delivery-distribution-total_count">
                    <thead>
                        <tr class="timing-total__table-row timing-total__table-row_head">
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_small timing-total__table-cell_vat"
                                scope="col"
                                @click="setSorting('deliveryTimeDistribution', 'title')"
                            >
                                <span class="timing-total__cell-content timing-total__cell-content_columns timing-total__cell-content_left">
                                    <span class="timing-total__table-value">Источник</span>
                                    <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                        v-if="sortings[modeId].field === 'title'"
                                        :icon="sortings[modeId].direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_small"
                                v-for="deliveryTimeDistrubutionItem in totalDeliveryTimeDistrubution"
                                :key="deliveryTimeDistrubutionItem.id"
                                scope="col"
                                @click="setSorting('deliveryTimeDistribution', deliveryTimeDistrubutionItem.id)"
                            >
                                <span class="timing-total__cell-content timing-total__cell-content_columns timing-total__cell-content_right">
                                    <span class="timing-total__table-value">{{deliveryTimeDistrubutionItem.title}}</span>
                                    <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                        v-if="sortings[modeId].field === deliveryTimeDistrubutionItem.id"
                                        :icon="sortings[modeId].direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                            <th class="timing-total__table-cell timing-total__table-cell_head timing-total__table-cell_sortable timing-total__table-cell_small timing-total__table-cell_vat"
                                scope="col"
                                @click="setSorting('deliveryTimeDistribution', 'total_count')"
                            >
                                <span class="timing-total__cell-content timing-total__cell-content_columns timing-total__cell-content_right">
                                    <span class="timing-total__table-value">Всего заказов</span>
                                    <svg-icon class="timing-total__table-value timing-total__table-value_icon"
                                        v-if="sortings[modeId].field === 'total_count'"
                                        :icon="sortings[modeId].direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                    />
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="timing-total__table-row timing-total__table-row_body timing-total__table-row_pinned"
                            v-if="restaurants.length > 1"
                        >
                            <td class="timing-total__table-cell timing-total__table-cell_title">Итого</td>
                            <td class="timing-total__table-cell timing-total__table-cell_vat"
                                v-for="deliveryTimeDistrubutionItem in totalDeliveryTimeDistrubution"
                                :key="deliveryTimeDistrubutionItem.id"
                            >
                                <span class="timing-total__cell-content timing-total__cell-content_rows timing-total__cell-content_right">
                                    <span class="timing-total__table-value">{{deliveryTimeDistrubutionItem.count | intFormat}}</span>
                                    <popper class="timing-total__table-value timing-total__table-value_tooltip"
                                        trigger="hover"
                                        :options="popperOptions"
                                    >
                                        <span class="popper timing-total__tooltip">Доля заказов с доставкой "{{deliveryTimeDistrubutionItem.title}}" среди всех заказов всех ресторанов за выбранный период отчёта</span>
                                        <percentage slot="reference"
                                            :part="deliveryTimeDistrubutionItem.count"
                                            :whole="total.orders_count"
                                        />
                                    </popper>
                                </span>
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_vat">
                                <span class="timing-total__cell-content timing-total__cell-content_rows timing-total__cell-content_right">
                                    <span class="timing-total__table-value">{{total.orders_count | intFormat}}</span>
                                 </span>
                            </td>
                        </tr>
                        <tr class="timing-total__table-row timing-total__table-row_body"
                            v-for="restaurant in restaurants"
                            :key="restaurant.id"
                        >
                            <td class="timing-total__table-cell timing-total__table-cell_title">
                                <router-link class="daterange-report__link link link_red"
                                    :to="{ name: 'TimingRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть тайминг ресторана ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_vat"
                                v-for="deliveryTimeDistrubutionItem in restaurant.timeframe"
                                :key="deliveryTimeDistrubutionItem.id"
                            >
                                <span class="timing-total__cell-content timing-total__cell-content_rows timing-total__cell-content_right">
                                    <span class="timing-total__table-value">{{deliveryTimeDistrubutionItem.count | intFormat}}</span>
                                    <popper class="timing-total__table-value timing-total__table-value_tooltip"
                                        trigger="hover"
                                        :options="popperOptions"
                                    >
                                        <span class="popper timing-total__tooltip">Доля заказов с доставкой "{{deliveryTimeDistrubutionItem.title}}" ресторана "{{restaurant.title}}" среди всех заказов этого ресторана за выбранный период отчёта</span>
                                        <percentage slot="reference"
                                            :part="deliveryTimeDistrubutionItem.count"
                                            :whole="restaurant.total.orders_count"
                                        />
                                    </popper>
                                 </span>
                            </td>
                            <td class="timing-total__table-cell timing-total__table-cell_vat">
                                <span class="timing-total__cell-content timing-total__cell-content_rows timing-total__cell-content_right">
                                    <span class="timing-total__table-value">{{restaurant.total.orders_count | intFormat}}</span>
                                 </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p class="timing-total__text timing-total__text_delivery-time-distribution">Время доставки рассчитывается с момента отправки заказа (Send Time) до фактического времени доставки (Actual Time), но если это время будет менее 3 мин., то время доставки будет рассчитываться со времени отправки заказа (Send Time) до времени закрытия доставки (Close Time).</p>
        </div>
    </section>
</template>

<script>
    import { mapMutations } from "vuex";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import Difference from "@/components/Difference";
    import Percentage from "@/components/Percentage";

    export default{
        name: "TimingTotal",
        props: {
            status: {
                type: String,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            showLateDistributionSelect: {
                type: Boolean,
                required: true
            },
            lateDistributionItemId: {
                type: String,
                required: true
            },
            getHoursChartOptions: {
                type: Function,
                required: true
            },
            reportTiming: {
                required: true
            },
            standard: {
                required: true
            },
            total: {
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        components: {
            Difference,
            Percentage,
        },
        data() {
            return {
                sortings: {
                    averageTime: {
                        field: "total_duration",
                        direction: "ASC"
                    },
                    lateData: {
                        field: "total_percent_late",
                        direction: "ASC"
                    },
                    deliveryTimeDistribution: {
                        field: "menee_30_minyt",
                        direction: "DESC"
                    },
                },
            };
        },
        computed: {
            restaurants() {
                if (this.modeId === "lateDistribution") {
                    return this.reportTiming?.restaurants;
                }

                return this.reportTiming?.restaurants?.sort((restaurant1, restaurant2) => {
                    if (this.sortings[this.modeId].field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sortings[this.modeId].direction
                        );
                    } else if (this.modeId === "deliveryTimeDistribution") {
                        if (this.sortings[this.modeId].field === "total_count") {
                            return compareNumbers(
                                restaurant1.total.orders_count,
                                restaurant2.total.orders_count,
                                this.sortings[this.modeId].direction
                            );
                        }

                        let restaurant1DistributionItem = restaurant1?.timeframe?.find(({ id }) => id === this.sortings[this.modeId].field);
                        let restaurant2DistributionItem = restaurant2?.timeframe?.find(({ id }) => id === this.sortings[this.modeId].field);

                        return compareNumbers(
                            restaurant1DistributionItem.count,
                            restaurant2DistributionItem.count,
                            this.sortings[this.modeId].direction
                        );
                    }

                    return compareNumbers(
                        restaurant1.total[this.sortings[this.modeId].field],
                        restaurant2.total[this.sortings[this.modeId].field],
                        this.sortings[this.modeId].direction
                    );
                });
            },
            totalHours() {
                return this.reportTiming?.total_hours;
            },
            lateDistributionHours() {
                if (this.lateDistributionItemId === "total") {
                    return this.totalHours;
                }

                return this.restaurants?.find(({ id }) => id === this.lateDistributionItemId)?.hours;
            },
            hoursChartOptions() {
                return this.getHoursChartOptions(this.lateDistributionHours);
            },
            totalDeliveryTimeDistrubution() {
                return this.reportTiming?.total?.timeframe;
            },
            popperOptions() {
                return {
                    modifiers: {
                        offset: { offset: '0,10px' }
                    }
                };
            }
        },
        methods: {
            ...mapMutations([
                "toggleReportTotalLink",
                "setTimingParameter",
            ]),
            setSorting(tableId, field) {
                if (this.sortings[tableId].field === field) {
                    this.sortings[tableId].direction = this.sortings[tableId].direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sortings[tableId].field = field
                }
            }
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "TimingRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else {
                if (this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(false);
                }

                if (!this.showLateDistributionSelect) {
                    this.setTimingParameter({ parameter: "showLateDistributionSelect", value: true });
                }
            }
        }
    }
</script>

<style lang="scss">
    .timing-total__box {
        padding: 16px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: $gray-line;
        }
        &::-webkit-scrollbar-track {
            border-width: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $text-gray-secondary;
        }

        & + & {
            margin-top: 15px;
        }
    }
    .timing-total__table {
        min-width: 100%;
    }
    .timing-total__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .timing-total__table-column {
        &_title {
            width: 20%;
        }
        &_cooking-duration {
            width: 13.33%;
        }
        &_stand-duration {
            width: 13.33%;
        }
        &_waiting-duration {
            width: 13.33%;
        }
        &_delivery-duration {
            width: 13.33%;
        }
        &_total-duration {
            width: 13.33%;
        }
        &_return-duration {
            width: 13.33%;
        }
        &_late-title {
            width: 18%;
        }
        &_late-value {
            width: calc(82% / 12);
        }

        &_delivery-distribution-title {
            min-width: 200px;
        }

        &_delivery-distribution {
            min-width: 95px;
        }

        &_delivery-distribution-menee_30_minyt {
            min-width: 100px;
        }

        &_delivery-distribution-total_count {
            min-width: 100px;
        }
    }
    .timing-total__table-row {
        &_body {
            .timing-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .timing-total__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .timing-total__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_low {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &_head {
            padding: 8px;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_centered {
            text-align: center;
        }

        &_right {
            text-align: right;
        }

        &_small {
            font-size: 16px;
        }

        &_baseline {
            vertical-align: baseline;
        }

        &_vat {
            vertical-align: top;
        }
    }
    .timing-total__cell-content {
        display: grid;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.timing-total__cell-content_left {
                justify-content: start;
            }

            &.timing-total__cell-content_right {
                justify-content: end;
                text-align: right;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.timing-total__cell-content_left {
                justify-items: start;
            }

            &.timing-total__cell-content_right {
                justify-items: end;
            }
        }
    }
    .timing-total__table-value {
        line-height: 1;
        vertical-align: middle;

        & + & {
            margin-left: 4px;
        }

        &_icon {
            width: 16px;
            height: 16px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }

        &_tooltip {
            cursor: pointer;
            border-bottom: 1px dotted;
        }
    }
    .timing-total__tooltip {
        max-width: 300px;
        padding: 5px 10px;
        text-align: left;
        background-color: #fff;
    }
    .timing-total__text {
        &_delivery-time-distribution {
            margin-top: 15px;
        }
    }
</style>
